import { get,post} from '@/utils/axios/axios'

export const getChannelProductList = data => get("/m/channelProduct/list",data)

export const addInsure = data => post("/m/batch/insure",data)

export const unifyPay = data => get("/m/unifyPay",data)

export const payInfoByBizNo = data => get("/m/payInfoByBizNo",data)

export const getOauthPageUrl = data => get("/m/getOauthPageUrl",data)

export const getQrImageByBase64 = data => get("/m/getQrImageByBase64",data)

export const getWxRedirect = data => get("m/wxRedirect",data)

export const getOrderList = data => get("/m/order/list",data)

export const getProductInfoByPlanCode = data => get("/m/product/infoByPlanCode",data)

export const getInsureQrURL = data => get("/m/insure/qrUrl",data)

export const getDrivingSchool = data => get("/m/drivingSchool",data)


