<template>
  <div id="ProductList" style="height: 100%">
    <div style="height: 28px"></div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id" size="large">
        <h2>{{item.productName}}:{{item.planName}}</h2>
        <p><van-tag type="primary" size="medium">{{item.company.shortName}}</van-tag></p>
        <div v-if="isShowSafeguardClause">
          <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">保障条款</van-divider>
          <p v-for="clause in item.clauses" :key="'safeguard' + clause.id">
            <samp v-if="clause.clauseType == 1">
              <samp v-html="clause.clauseContent"></samp>: <samp style="color: #1f1235; font-size: larger; font-weight: bold;" v-text="clause.coverage"></samp>
            </samp>
          </p>
        </div>
        <div v-if="isShowWarningClause">
          <van-divider :style="{ color: '#ff6e6c', borderColor: '#ff6e6c', padding: '0 16px' }">注意事项</van-divider>
          <p v-for="clause in item.clauses" :key="'notice' + clause.id" >
            <samp v-if="clause.clauseType == 2" v-html="clause.clauseContent">
            </samp>
          </p>
        </div>

        <div v-if="isShowOtherClause">
          <p v-for="clause in item.clauses" :key="'notice' + clause.id" >
            <samp v-html="clause.clauseContent"></samp>
          </p>
        </div>

        <van-row type="flex" justify="end">
          <van-col span="6">
            <h1 style="color: #F56C6C" v-text="item.premium + '元'"></h1>
          </van-col>
        </van-row>

        <van-row type="flex" justify="end">

          <van-col span="6">
            <van-button type="primary" size="large" @click="insureProduct(item)">录入</van-button>
          </van-col>
        </van-row>
        <van-row style="height: 15px"></van-row>
      </van-cell>
    </van-list>
    <van-empty v-if="empty.isShow" :image="empty.image" :description="empty.description" />
  </div>
</template>

<script>
import {getChannelProductList} from "@/api/insure";

export default {
  name:"PolicyList",
  data() {
    return {
      formDate:{
        channelCode:""
      },
      list: [],
      loading: false,
      finished: false,
      isShowSafeguardClause:false,
      isShowWarningClause:false,
      isShowOtherClause:false,
      empty:{
        isShow:false,
        image:"default",
        description:"保单不存在",
      },
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      this.formDate.channelCode = this.$route.query.channelCode;
      this.formDate.productCode = this.$route.query.productCode;
      this.formDate.planCode = this.$route.query.planCode;
      this.loading = true;
      getChannelProductList({
        channelCode: this.formDate.channelCode,
        productCode: this.formDate.productCode,
        planCode: this.formDate.planCode,
        pageNum: 1,
        pageSize:100}).then(res => {
        if (200 === res.code) {
          this.list = res.data;
          if (0 === this.list.length) {
            this.empty.isShow = true;
          }else {
            for (var indx in this.list) {
              var plan = this.list[indx];
              if (plan.clauses.length > 0) {
                for (var idx in plan.clauses) {
                  var cl = plan.clauses[idx];
                  if (1 == cl.clauseType) {
                    this.isShowSafeguardClause = true;
                  }
                  if (2== cl.clauseType) {
                    this.isShowWarningClause = true;
                  }
                  if (3 == cl.clauseType) {
                    this.isShowOtherClause = true;
                  }
                }
              }
            }
          }
        }else {
          this.empty.isShow = true;
          this.empty.description = res.result.msg;
        }
        this.finished = true;
      }).catch(()=>{
        this.finished = true;
        this.empty.isShow = true;
        this.empty.image = "network";
        this.empty.description = "请求失败";
      });
    },
    insureProduct(item){
      this.$router.push({path: '/insure/insure', query: {channelCode: this.formDate.channelCode, planCode: item.planCode}});
    }
  },
}
</script>
